// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Statusofinvoices from "../../blocks/statusofinvoices/src/Statusofinvoices";
import Cfcustomizedvisualanalytics4 from "../../blocks/cfcustomizedvisualanalytics4/src/Cfcustomizedvisualanalytics4";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Cfcolorcodeautomation4 from "../../blocks/cfcolorcodeautomation4/src/Cfcolorcodeautomation4";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Cfautosuggestionautosave5 from "../../blocks/cfautosuggestionautosave5/src/Cfautosuggestionautosave5";
import Editinvoices from "../../blocks/editinvoices/src/Editinvoices";
import Notifications from "../../blocks/notifications/src/Notifications";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Download from "../../blocks/download/src/Download";
import DownloadUpload from "../../blocks/download/src/DownloadUpload";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import CreateComment from "../../blocks/comments/src/CreateComment";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cfchecklistdefinition4 from "../../blocks/cfchecklistdefinition4/src/Cfchecklistdefinition4";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Cftasktrackmanagementboard from "../../blocks/cftasktrackmanagementboard/src/Cftasktrackmanagementboard";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Integrationwithexistinghrmsplatform from "../../blocks/integrationwithexistinghrmsplatform/src/Integrationwithexistinghrmsplatform";
import Cfcustomizablefilterconditions4 from "../../blocks/cfcustomizablefilterconditions4/src/Cfcustomizablefilterconditions4";
import Pagination from "../../blocks/pagination/src/Pagination";
import Cfadditionalrolesupport5 from "../../blocks/cfadditionalrolesupport5/src/Cfadditionalrolesupport5";
import Timestamp from "../../blocks/timestamp/src/Timestamp";
import Cffieldvaluesbasedflowdefinition2 from "../../blocks/cffieldvaluesbasedflowdefinition2/src/Cffieldvaluesbasedflowdefinition2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Cfworkspacemappingandleavereport3 from "../../blocks/cfworkspacemappingandleavereport3/src/Cfworkspacemappingandleavereport3";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Statusofinvoices:{
 component:Statusofinvoices,
path:"/Statusofinvoices"},
Cfcustomizedvisualanalytics4:{
 component:Cfcustomizedvisualanalytics4,
path:"/Cfcustomizedvisualanalytics4"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Cfcolorcodeautomation4:{
 component:Cfcolorcodeautomation4,
path:"/Cfcolorcodeautomation4"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Cfautosuggestionautosave5:{
 component:Cfautosuggestionautosave5,
path:"/Cfautosuggestionautosave5"},
Editinvoices:{
 component:Editinvoices,
path:"/Editinvoices"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Download:{
 component:Download,
path:"/Download"},
DownloadUpload:{
 component:DownloadUpload,
path:"/DownloadUpload"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cfchecklistdefinition4:{
 component:Cfchecklistdefinition4,
path:"/Cfchecklistdefinition4"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Cftasktrackmanagementboard:{
 component:Cftasktrackmanagementboard,
path:"/Cftasktrackmanagementboard"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Integrationwithexistinghrmsplatform:{
 component:Integrationwithexistinghrmsplatform,
path:"/Integrationwithexistinghrmsplatform"},
Cfcustomizablefilterconditions4:{
 component:Cfcustomizablefilterconditions4,
path:"/Cfcustomizablefilterconditions4"},
Pagination:{
 component:Pagination,
path:"/Pagination"},
Cfadditionalrolesupport5:{
 component:Cfadditionalrolesupport5,
path:"/Cfadditionalrolesupport5"},
Timestamp:{
 component:Timestamp,
path:"/Timestamp"},
Cffieldvaluesbasedflowdefinition2:{
 component:Cffieldvaluesbasedflowdefinition2,
path:"/Cffieldvaluesbasedflowdefinition2"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Cfworkspacemappingandleavereport3:{
 component:Cfworkspacemappingandleavereport3,
path:"/Cfworkspacemappingandleavereport3"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;